import "./index.css";
import { Helmet } from "react-helmet";

import Accordion from "react-bootstrap/Accordion";
import BannerImage from "../../pic/Banner-PYO.jpg";

function PYO() {
  return (
    <>
      <Helmet>
        <title>Kersbrook Cherry Farm - PYO</title>
        <meta
          name="description"
          content="Please check our opening time before you come. You can have a nice outdoor event here, not only pick cherries, but also have a family party."
        />
        <meta
          name="keywords"
          content="Pick your own, PYO, family party, picnic"
        />
      </Helmet>
      <div className="bannerWrapper">
        <img src={BannerImage} alt="" className="pageBanner" />
      </div>
      <div className="contentWrapper">
        <div className="content">
          <h1 className="pageHeading">Pick Your Own</h1>
          <span>
          <div className="strong">
            It's a wonderful cherry season🍒🍒🍒. Thank you for your support.
            Wish you a happy new year, see you next cherry season🍒🍒🍒🍒.
          </div>
          <p>

              Please check our website and {}
              <a
                href="https://www.facebook.com/KersbrookCherryFarm/"
                target="_blank"
                rel="noreferrer"
                className="inline"
              >
                Facebook
              </a>
              {} for the latest information.
            </p>
          </span>
          <div className="pyo">
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Preparation</Accordion.Header>
                <Accordion.Body>
                  <p>
                    Please check available time before you come. We will update
                    daily both on the website and our Facebook.
                  </p>
                  <p>
                    Also pay attention on the weather, appropriate clothes. Be
                    careful to warmth and sun protection. The following items
                    are recommended: drinking water, sunglasses, hat and
                    closed-toe shoes.
                  </p>
                  <p>
                    Please notice the COVID-19 policy. Wearing mask and keeping
                    social distance are important.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>During picking</Accordion.Header>
                <Accordion.Body>
                  <p>
                    When you arrive, please follow the roadside directions to
                    the parking area.
                  </p>
                  <p>
                    <strong>Entrance Fee: $5.00 ( Free for child under 12 )</strong>
                  </p>
                  <p>Booking for groups is essential.</p>
                  <p>
                    Before picking, we will offer you bucket and guide you to
                    the open orchard. Our friendly staff will demonstrate
                    correct way to pick. <br />
                    Please avoid wasting, throwing and damaging may cause you
                    leave.
                  </p>
                  <p>
                    Bing, Kristin, Ron and Sunburst are good to be picked now.
                  </p>
                  <p>
                    After picking, you can wight your cherry in the shed door.
                    You need to pay for all you picked. Or purchase our packed
                    box directly. We offer boxes in different weight.
                  </p>
                  <p>We offer Ice Cream, Matcha, chocolate and Coke as well.</p>
                  <p>
                    We also offer beautiful environment where you can hold a
                    party or picnic. Be aware of your surroundings, especially
                    around the lake. Pay attention to children all the time.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Take care your cherry</Accordion.Header>
                <Accordion.Body>
                  <p>
                    Loosely pack cherries into the box and they are ready to go.
                    Put them inside the refrigerator and don't wash until you
                    eat it.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
    </>
  );
}

export default PYO;
