import "./index.css";
import { Link } from "react-router-dom";
import logo from "../../pic/logo.jpg";
import fbIcon from "../../pic/fb_icon.png";
import insIcon from "../../pic/Instagram_icon.png";


import NavBar from "../NavBar";

function Footer() {
  return (
    <>
      <div className="footerWrapper">
        <div className="footerContainer">
          <NavBar />
          <div className="footerLogoWrapper">
            <Link to="/" className="footerLogoLink">
              <img alt="Logo" src={logo} className="footerLogo" />
            </Link>
          </div>
          <div className="footerSocialWrapper">
            <a
              href="mailto:kersbrookcherryfarm@gmail.com"
              className="footerEmail"
            >
              kersbrookcherryfarm@gmail.com
            </a>
            <div className="footerNumber">
                          <a href="tel:+61420944788" className="footerPhone">
              +61420944788
            </a>
                                        <a href="tel:+6183892194" className="footerPhone">
                /  83892194
              </a>
            </div>
            <div className="footerSocial">
            <a
              href="https://www.facebook.com/KersbrookCherryFarm/"
              target="_blank"
              rel="noreferrer"
              className="footerFB"
            >
              <img alt="Facebook" src={fbIcon} className="fbIcon" />
            </a>
            <a
                href="https://www.instagram.com/kersbrookcherry/"
                target="_blank"
                rel="noreferrer"
                className="footerFB"
              >
                <img alt="Instagram" src={insIcon} className="insIcon" /> 
              </a>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
