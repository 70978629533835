import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import Gallery_1 from "../../pic/g_1.jpg";
import Gallery_2 from "../../pic/g_2.jpg";
import Gallery_3 from "../../pic/g_3.jpg";
import Gallery_4 from "../../pic/g_4.jpg";
import Gallery_5 from "../../pic/g_5.jpg";
import Gallery_6 from "../../pic/g_6.jpg";
import Gallery_7 from "../../pic/g_7.jpg";
import Gallery_8 from "../../pic/g_8.jpg";
import Gallery_9 from "../../pic/g_9.jpg";
import Gallery_10 from "../../pic/g_10.jpg";
import Gallery_11 from "../../pic/g_11.jpg";
import Gallery_12 from "../../pic/g_12.jpg";
import Gallery_13 from "../../pic/g_13.jpg";
import Gallery_14 from "../../pic/g_14.jpg";
import Gallery_15 from "../../pic/g_15.jpg";
import Gallery_16 from "../../pic/g_16.jpg";
import Gallery_17 from "../../pic/g_17.jpg";
import Gallery_18 from "../../pic/g_18.jpg";
import Gallery_19 from "../../pic/g_19.jpg";
import Gallery_20 from "../../pic/g_20.jpg";
import Gallery_21 from "../../pic/g_21.jpg";
import Gallery_22 from "../../pic/g_22.jpg";
import Gallery_23 from "../../pic/g_23.jpg";
import Gallery_thumb_1 from "../../pic/gt_1.jpg";
import Gallery_thumb_2 from "../../pic/gt_2.jpg";
import Gallery_thumb_3 from "../../pic/gt_3.jpg";
import Gallery_thumb_4 from "../../pic/gt_4.jpg";
import Gallery_thumb_5 from "../../pic/gt_5.jpg";
import Gallery_thumb_6 from "../../pic/gt_6.jpg";
import Gallery_thumb_7 from "../../pic/gt_7.jpg";
import Gallery_thumb_8 from "../../pic/gt_8.jpg";
import Gallery_thumb_9 from "../../pic/gt_9.jpg";
import Gallery_thumb_10 from "../../pic/gt_10.jpg";
import Gallery_thumb_11 from "../../pic/gt_11.jpg";
import Gallery_thumb_12 from "../../pic/gt_12.jpg";
import Gallery_thumb_13 from "../../pic/gt_13.jpg";
import Gallery_thumb_14 from "../../pic/gt_14.jpg";
import Gallery_thumb_15 from "../../pic/gt_15.jpg";
import Gallery_thumb_16 from "../../pic/gt_16.jpg";
import Gallery_thumb_17 from "../../pic/gt_17.jpg";
import Gallery_thumb_18 from "../../pic/gt_18.jpg";
import Gallery_thumb_19 from "../../pic/gt_19.jpg";
import Gallery_thumb_20 from "../../pic/gt_20.jpg";
import Gallery_thumb_21 from "../../pic/gt_21.jpg";
import Gallery_thumb_22 from "../../pic/gt_22.jpg";
import Gallery_thumb_23 from "../../pic/gt_23.jpg";

import "./index.css";

function Gallery() {
  return (
    <SimpleReactLightbox>
      <SRLWrapper>
        <div className="galleryWrapper">
          <a href={Gallery_1} className="galleryLink">
            <img
              src={Gallery_thumb_1}
              className="galleryThumb"
              alt="Gallery 1"
            />
          </a>
          <a href={Gallery_2} className="galleryLink">
            <img
              src={Gallery_thumb_2}
              className="galleryThumb"
              alt="Gallery 2"
            />
          </a>
          <a href={Gallery_3} className="galleryLink">
            <img
              src={Gallery_thumb_3}
              className="galleryThumb"
              alt="Gallery 3"
            />
          </a>
          <a href={Gallery_4} className="galleryLink">
            <img
              src={Gallery_thumb_4}
              className="galleryThumb"
              alt="Gallery 4"
            />
          </a>
          <a href={Gallery_5} className="galleryLink">
            <img
              src={Gallery_thumb_5}
              className="galleryThumb"
              alt="Gallery 5"
            />
          </a>
          <a href={Gallery_6} className="galleryLink">
            <img
              src={Gallery_thumb_6}
              className="galleryThumb"
              alt="Gallery 6"
            />
          </a>
          <a href={Gallery_7} className="galleryLink">
            <img
              src={Gallery_thumb_7}
              className="galleryThumb"
              alt="Gallery 7"
            />
          </a>
          <a href={Gallery_8} className="galleryLink">
            <img
              src={Gallery_thumb_8}
              className="galleryThumb"
              alt="Gallery 8"
            />
          </a>
          <a href={Gallery_9} className="galleryLink">
            <img
              src={Gallery_thumb_9}
              className="galleryThumb"
              alt="Gallery 9"
            />
          </a>
          <a href={Gallery_10} className="galleryLink">
            <img
              src={Gallery_thumb_10}
              className="galleryThumb"
              alt="Gallery 10"
            />
          </a>
          <a href={Gallery_11} className="galleryLink">
            <img
              src={Gallery_thumb_11}
              className="galleryThumb"
              alt="Gallery 11"
            />
          </a>
          <a href={Gallery_12} className="galleryLink">
            <img
              src={Gallery_thumb_12}
              className="galleryThumb"
              alt="Gallery 12"
            />
          </a>
          <a href={Gallery_13} className="galleryLink">
            <img
              src={Gallery_thumb_13}
              className="galleryThumb"
              alt="Gallery 13"
            />
          </a>
          <a href={Gallery_14} className="galleryLink">
            <img
              src={Gallery_thumb_14}
              className="galleryThumb"
              alt="Gallery 14"
            />
          </a>
          <a href={Gallery_15} className="galleryLink">
            <img
              src={Gallery_thumb_15}
              className="galleryThumb"
              alt="Gallery 15"
            />
          </a>
          <a href={Gallery_16} className="galleryLink">
            <img
              src={Gallery_thumb_16}
              className="galleryThumb"
              alt="Gallery 16"
            />
          </a>
          <a href={Gallery_17} className="galleryLink">
            <img
              src={Gallery_thumb_17}
              className="galleryThumb"
              alt="Gallery 17"
            />
          </a>
          <a href={Gallery_18} className="galleryLink">
            <img
              src={Gallery_thumb_18}
              className="galleryThumb"
              alt="Gallery 18"
            />
          </a>
          <a href={Gallery_19} className="galleryLink">
            <img
              src={Gallery_thumb_19}
              className="galleryThumb"
              alt="Gallery 19"
            />
          </a>
          <a href={Gallery_20} className="galleryLink">
            <img
              src={Gallery_thumb_20}
              className="galleryThumb"
              alt="Gallery 20"
            />
          </a>
          <a href={Gallery_21} className="galleryLink">
            <img
              src={Gallery_thumb_21}
              className="galleryThumb"
              alt="Gallery 21"
            />
          </a>
          <a href={Gallery_22} className="galleryLink">
            <img
              src={Gallery_thumb_22}
              className="galleryThumb"
              alt="Gallery 22"
            />
          </a>
          <a href={Gallery_23} className="galleryLink">
            <img
              src={Gallery_thumb_23}
              className="galleryThumb"
              alt="Gallery 23"
            />
          </a>
        </div>
      </SRLWrapper>
    </SimpleReactLightbox>
  );
}

export default Gallery;
