import "./index.css";
import { NavLink } from "react-router-dom";

function NavBar() {
  return (
    <>
      <div className="navBar">
        <NavLink exact to="/" className="navItem">
          HOME
        </NavLink>
        <NavLink to="/aboutus" className="navItem">
          ABOUT US
        </NavLink>
        <NavLink to="/pyo" className="navItem">
          PYO
        </NavLink>
        <NavLink to="/contactus" className="navItem">
          CONTACT US
        </NavLink>
      </div>
    </>
  );
}

export default NavBar;
