import "./index.css";
import Carousel from "react-bootstrap/Carousel";
import BannerImage from "../../pic/Banner-Home.jpg";
import BannerImage2 from "../../pic/Banner-About.jpg";
import BannerImage3 from "../../pic/Banner-PYO.jpg";
import BannerImage4 from "../../pic/Banner-Contact.jpg";
import BannerImage5 from "../../pic/endOfSeason.jpg"

function HomeBanner() {
  return (
    <Carousel fade>
        <Carousel.Item>
        <img
          className="d-block mx-auto w-100 mx"
          src={BannerImage5}
          alt="First Banner"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block mx-auto w-100 mx"
          src={BannerImage}
          alt="First Banner"
        />
        <Carousel.Caption>
          <h3>CLOSED FOR THE SEASON</h3>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block mx-auto w-100 mx"
          src={BannerImage2}
          alt="Second Banner"
        />
        <Carousel.Caption>
          <h3>CLOSED FOR THE SEASON</h3>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block mx-auto w-100 mx"
          src={BannerImage3}
          alt="Third Banner"
        />
        <Carousel.Caption>
          <h3>CLOSED FOR THE SEASON</h3>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block mx-auto w-100 mx"
          src={BannerImage4}
          alt="Forth Banner"
        />
        <Carousel.Caption>
          <h3>CLOSED FOR THE SEASON</h3>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default HomeBanner;
