import Header from "./component/Header";
import Footer from "./component/Footer";
import { Switch, Route } from "react-router";
import { Helmet } from "react-helmet";
import Home from "./Pages/Home";
import About from "./Pages/About";
import PYO from "./Pages/PYO";
import Contact from "./Pages/Contact";

function App() {
  return (
    <>
      <Header />
      <Helmet>
        <title>Kersbrook Cherry Farm</title>
        <meta
          name="description"
          content="Kersbrook Cherry Farm, 1718 S Para Rd, Chain of Ponds SA 5231, a family owned cherry farm, a beautiful place to purchase or pick your own cherry."
        />
        <meta
          name="keywords"
          content="Kersbrook Cherry Farm, PYO, Cherry Farm, Pick your cherry, Kersbrook"
        />
      </Helmet>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/aboutus" component={About} />
        <Route path="/pyo" component={PYO} />
        <Route path="/contactus" component={Contact} />
      </Switch>
      <Footer />
    </>
  );
}

export default App;
