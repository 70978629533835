import "./index.css";
import { Link } from "react-router-dom";
import logo from "../../pic/logo.jpg";
import { Navbar, Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";

function Header() {
  return (
    <>
      <div className="headerContainer">
        <Navbar expand="lg" bg="white">
          <Navbar.Brand as={Link} exact to="/">
            <img alt="Logo" src={logo} className="logoImg" /> Kersbrook Cherry
            Farm
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>

            <Nav>
              <NavLink exact to="/" className="navItem">
                HOME
              </NavLink>
              <NavLink to="/aboutus" className="navItem">
                ABOUT US
              </NavLink>
              <NavLink to="/pyo" className="navItem">
                PYO
              </NavLink>
              <NavLink to="/contactus" className="navItem">
                CONTACT US
              </NavLink>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </>
  );
}

export default Header;
