import "./index.css";
import { Helmet } from "react-helmet";
import ContactForm from "../../component/ContactForm";
import BannerImage from "../../pic/Banner-Contact.jpg";
import fbIcon from "../../pic/fb_icon.png";
import insIcon from "../../pic/Instagram_icon.png"

function Contact() {
  return (
    <>
      <Helmet>
        <title>Kersbrook Cherry Farm - Contact us</title>
        <meta
          name="description"
          content="Please sent us your feedback, so we can offer better service."
        />
        <meta
          name="keywords"
          content="Kersbrook Cherry Farm Contact us, feedback, form, details"
        />
      </Helmet>
      <div className="bannerWrapper">
        <img src={BannerImage} alt="" className="pageBanner" />
      </div>
      <div className="contentWrapper">
        <div className="content">
          <h1 className="pageHeading">Contact us</h1>
          <div className="innerWrapper">
            <div className="contactFormWrapper">
              <ContactForm />
            </div>
            <div className="contactDetail">
              <a
                href="https://goo.gl/maps/n1bSRi5GZqG2ECk87"
                target="_blank"
                rel="noreferrer"
                className="contactItem"
              >
                <strong>Address</strong>: 1718 South Para Road, Chain of Ponds,
                SA 5231
              </a>
              <a
                href="mailto:kersbrookcherryfarm@gmail.com"
                className="contactItem"
              >
                <strong>Email</strong>: kersbrookcherryfarm@gmail.com
              </a>
                            <a href="tel:+6183892194" className="contactItem">
                <strong>Phone Number</strong>: 83892194
              </a>
              <a href="tel:+61420944788" className="contactItem">
                <strong>Mobile Number</strong>: +61420944788
              </a>
              <a
                href="https://www.facebook.com/KersbrookCherryFarm/"
                target="_blank"
                rel="noreferrer"
                className="footerFB"
              >
                <img alt="Facebook" src={fbIcon} className="fbIcon" /> Kersbrook
                Cherry Farm
              </a>
                            <a
                href="https://www.instagram.com/kersbrookcherry/"
                target="_blank"
                rel="noreferrer"
                className="footerFB"
              >
                <img alt="Instagram" src={insIcon} className="insIcon" /> Kersbrook
                Cherry Farm
              </a>
            </div>
          </div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3276.075824199993!2d138.83673181496997!3d-34.80403418040811!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ab74f665b047431%3A0x58d9251f18edd4a!2sKersbrook%20Cherry%20Farm!5e0!3m2!1sen!2sau!4v1632440874928!5m2!1sen!2sau"
            title="Google Map"
            width="100%"
            height="300"
            allowFullScreen
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </>
  );
}

export default Contact;
