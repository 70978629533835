import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import emailjs from "emailjs-com";
import "./index.css";

function ContactForm() {
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_ez6cuzg",
        "template_fa3t5iq",
        e.target,
        "user_maRyCnxWqVvOgtJiAq3hx"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  }

  return (
    <>
      <Form onSubmit={sendEmail}>
        <Form.Group md="4" controlId="validationCustom01">
          <Form.Label>Name*</Form.Label>
          <Form.Control required type="text" name="userName" />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </Form.Group>
        <br />
        <Form.Group md="4" controlId="validationCustom02">
          <Form.Label>Phone Number</Form.Label>
          <Form.Control type="text" name="phoneNumber" />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </Form.Group>
        <br />
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email Address*</Form.Label>
          <Form.Control required type="email" name="email" />
          <Form.Text className="text-muted">
            We'll never share your email with anyone else.
          </Form.Text>
        </Form.Group>
        <Form.Group controlId="floatingTextarea2" label="Comments">
          <Form.Control
            as="textarea"
            required
            placeholder="Leave a comment here"
            style={{ height: "100px" }}
            name="message"
          />
        </Form.Group>
        <Button variant="primary" type="submit" className="formButton">
          Submit
        </Button>
      </Form>
    </>
  );
}

export default ContactForm;
