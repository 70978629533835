import "./index.css";
import HomeBanner from "../../component/HomeBanner";
import Gallery from "../../component/Gallery";
function Home() {
  return (
    <>
      <HomeBanner />
      <div className="welcomeWrapper">
        <h1 className="pageTitle">Welcome</h1>
        <div className="welcomeContent">
          Welcome to the premium cherry farm{" "}
          <a
            href="https://goo.gl/maps/JqL2tJzXXb3qcywC7"
            target="_blank"
            rel="noreferrer"
            className="inline"
          >
            1718 South Para Rd, Chain of Ponds SA 5231
          </a>
          . Just 30kms, 40mins drive from city.
          <br />
          <div className="video">
            <video controls>
              <source src="/Videos/kersbrook1.mp4" type="video/mp4" />
            </video>
          </div>

                    <div className="strong">
            It's a wonderful cherry season🍒🍒🍒. Thank you for your support.
            Wish you a happy new year, see you next cherry season🍒🍒🍒🍒.
          </div>
          <p>

              Please check our website and {}
              <a
                href="https://www.facebook.com/KersbrookCherryFarm/"
                target="_blank"
                rel="noreferrer"
                className="inline"
              >
                Facebook
              </a>
              {} for the latest information.
            </p>

        </div>

        <div className="googleMapWrapper">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d104755.2161085621!2d138.65149060511678!3d-34.86626417204554!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x6ab735c7c526b33f%3A0x4033654628ec640!2sAdelaide%20SA!3m2!1d-34.9284989!2d138.60074559999998!4m5!1s0x6ab74f665b047431%3A0x58d9251f18edd4a!2zU291dGggQXVzdHJhbGlhLCBDaGFpbiBvZiBQb25kcywgUyBQYXJhIFJkLCBLZXJzYnJvb2sgQ2hlcnJ5IEZhcm3pgq7mlL_nvJbnoIE6IDUyMzE!3m2!1d-34.8040342!2d138.8389205!5e0!3m2!1sen!2sau!4v1633777906905!5m2!1sen!2sau"
            title="Google Map"
            width="100%"
            height="300"
            allowFullScreen
            loading="lazy"
          ></iframe>
        </div>
      </div>
      <Gallery />
    </>
  );
}

export default Home;
