import "./index.css";
import { Helmet } from "react-helmet";
import Accordion from "react-bootstrap/Accordion";
import BannerImage from "../../pic/Banner-About.jpg";
import Bing from "../../pic/Bing.jpg";
import Stella from "../../pic/Stella.jpg";
import Rainier from "../../pic/Rainier.jpg";
import Empress from "../../pic/Empress.jpg";

function About() {
  return (
    <>
      <Helmet>
        <title>Kersbrook Cherry farm - About Us</title>
        <meta
          name="description"
          content="Our farm has many varieties of cherry, you can always find the best one."
        />
        <meta
          name="keywords"
          content="Kersbroom Cherry Farm, About us, Cherry type, Variety of Cherry"
        />
      </Helmet>
      <div className="bannerWrapper">
        <img src={BannerImage} alt="" className="pageBanner" />
      </div>
      <div className="contentWrapper">
        <div className="content">
          <h1 className="pageHeading">About us</h1>
                    <div className="video">
            <video controls>
              <source src="/Videos/kersbrook2.mp4" type="video/mp4" />
            </video>
          </div>
          <span>
            Comprising approximately 41 hectares total area, and planted to
            approximately 22 hectares of cherry trees, Kersbrook Cherry Farm
            presents an outstanding opportunity to acquire a fantastic family
            farm experience only 34 kilometres from Adelaide’s CBD. Featuring
            packing and processing infrastructure, a cool room and automated
            grader, the property operates efficiently with a loyal customer base
            both locally and nationally.
          </span>
          <br />
          <br />

          <h3>About Cherry</h3>
          <div className="varieties">
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Bing</Accordion.Header>
                <Accordion.Body>
                  <img src={Bing} alt="Bing Cherry" className="cherryImg" />
                  Bings are large, dark and firm cherries.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Van</Accordion.Header>
                <Accordion.Body>
                  The Van cherry is a mid-season producer, ripening about 3 days
                  before Bing. Its fruits are medium-sized, with dark red flesh
                  and black skin, and sweet.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Stella</Accordion.Header>
                <Accordion.Body>
                  <img src={Stella} alt="Stella Cherry" className="cherryImg" />
                  The fruit of the Stella cultivar is large, heart-shaped, and
                  dark red, with overall excellent quality. It ripens about 1
                  week earlier than Bing.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Merchant</Accordion.Header>
                <Accordion.Body>
                  The fruit is medium to large and kidney-shaped. The soft skin
                  is half ripe dark red and black when fully ripe. The soft
                  flesh is sweet and aromatic and fruity. It has a medium burst
                  resistance and ripens in the 3rd to 4th cherry week.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>Ron</Accordion.Header>
                <Accordion.Body>
                  Rons are a large heart‐shaped cherry with black, slightly
                  thick skin and juicy deep red flesh. They are delicious either
                  eaten fresh or once cooked.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>Summit</Accordion.Header>
                <Accordion.Body>
                  The fruits are large and heart shape. Juicy and sweet.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>Kristin</Accordion.Header>
                <Accordion.Body>
                  They are large, red, juicy, aromatic and very sweet.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header>Sunburst</Accordion.Header>
                <Accordion.Body>
                  Dark red and sweet cherry, normally ripens in mid December.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="8">
                <Accordion.Header>Lamber</Accordion.Header>
                <Accordion.Body>
                  The cherry is large, richly colored, flavorful, and had a
                  small pit, and it immediately became popular.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="9">
                <Accordion.Header>Rainier</Accordion.Header>
                <Accordion.Body>
                  <img
                    src={Rainier}
                    alt="Rainier Cherry"
                    className="cherryImg"
                  />
                  They are sweet with a thin skin and thick creamy-yellow flesh.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="10">
                <Accordion.Header>Black Douglas</Accordion.Header>
                <Accordion.Body>
                  Black Douglas is a large black cherry with firm, meaty flesh.
                  This heart‐shaped variety is available late in the season.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="11">
                <Accordion.Header>Lapin</Accordion.Header>
                <Accordion.Body>
                  It is a hybrid of the Van and Stella cultivars. The fruit of
                  the Lapins cultivar is regarded as very high quality. It turns
                  deep red well before it is ready to pick, and unlike some
                  varieties it is sweet while still red. Lapins is a late-season
                  cherry, ripening about 2 weeks later than the Bing cherry.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="12">
                <Accordion.Header>Empress</Accordion.Header>
                <Accordion.Body>
                  <img
                    src={Empress}
                    alt="Empress Cherry"
                    className="cherryImg"
                  />
                  Empress cherries are a medium‐sized, deep plum‐coloured
                  variety with a subtly sweet flavour.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="13">
                <Accordion.Header>Simone</Accordion.Header>
                <Accordion.Body>
                  Sam cherries are a large, black variety with red flesh and a
                  mildly tart but rich flavour.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="14">
                <Accordion.Header>Chelan</Accordion.Header>
                <Accordion.Body>
                  It is a vigorous and early-bearing tree; with the fruit
                  ripening about 10-12 days earlier than 'Bing'.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="15">
                <Accordion.Header>Australis</Accordion.Header>
                <Accordion.Body></Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="16">
                <Accordion.Header>Earlise</Accordion.Header>
                <Accordion.Body></Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
